import {Page} from '../analytics/types/Events'

export enum BannerName {
  PrimaryOfferLockedDeal = 'app_exclusive_offer_1st_position',
  PrimaryOfferLockedDealV4 = 'app_exclusive_offer_1st_position_v4',
  PrimaryOfferLockedDealV5 = 'app_exclusive_offer_1st_position_v5',
  PrimaryOfferLockedDealV6 = 'app_exclusive_offer_1st_position_v6',
  PrimaryOfferLockedDealV8 = 'app_exclusive_offer_1st_position_v8',
  PrimaryOfferLockedDealFSMR = 'app_exclusive_offer_1st_position_fsmr',
  SplitBookingLockedDeal = 'app_exclusive_offer_split_booking',
  NonAnchorAppLockedDeal = 'app_exclusive_offer_non_anchors',
  ForcedAppExclusiveDeal = 'app_exclusive_url_param_forced',
  DealFreezeConfirmationBanner = 'deal_freeze_confirmation_banner',
  AccommodationPageRoomOffer = 'app_exclusive_offer_rooms',
  WebToAppDesktopPlacements = 'web_to_app_desktop_placements',
  WebToAppDesktopNonAnchorPlacements = 'web_to_app_desktop_non_anchor_placement'
}

/** @deprecated Use BooleanUrlParam from global types (types/global.d.ts) */
export type BooleanUrlParam = '0' | '1' | 0 | 1

// Either both or none
type ClickedOfferRateParams =
  | {
      clickedOfferRate: number | undefined
      currencyCode: string
    }
  | {
      clickedOfferRate?: never
      currencyCode?: never
    }

export type DeepLinkCustomSearchParams = {
  checkIn?: string
  checkOut?: string
  hotelId?: string
  placeId?: string
  rooms?: string
  userLocationSearch?: BooleanUrlParam
  address?: string
  isReturningUser?: BooleanUrlParam
  esd?: string | undefined
  epv?: string | undefined
  vclid?: string
  countryCode?: string
} & ClickedOfferRateParams

export type DeepLinkSearchParams = {
  anonymousId?: string
  searchId?: string
  optimizeAnchorRooms?: BooleanUrlParam
  optimizeRooms?: BooleanUrlParam
} & DeepLinkCustomSearchParams

export enum SearchType {
  hotelSearch = 'hotelSearch',
  placeSearch = 'placeSearch',
  userLocationSearch = 'userLocationSearch',
  unknown = 'unknown'
}

export type AppsFlyerDeepLinkData = {
  af_sub1?: string
  af_sub2?: string
  af_sub3?: string
  af_sub4?: string
  af_sub5?: string
  deep_link_value: Page | string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub1?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub2?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub3?: SearchType
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub4?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub5?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub6?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub7?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub8?: string
  deep_link_sub9?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub10?: string
  // TODO: remove DeepLinkSearchParams from here when we stop the support of old deep links
} & DeepLinkSearchParams
